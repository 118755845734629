import React from 'react';
import PropTypes from 'prop-types';
import { styled } from 'utils/emotion';
import media from 'utils/mediaqueries';
import IconArrowBack from 'components/Svg/IconArrowBack';
import IconArrowLeft from 'components/Svg/IconArrowLeft';

const BackLabel = styled('span')({
  fontSize: 16,
  color: 'white',
  display: 'none',

  [media('sm')]: {
    display: 'block',
  },
});

const ButtonBackStyled = styled('button')({
  position: 'absolute',
  zIndex: '1',
  outline: 'none',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',

  '&:hover, &:focus': {
    color: 'white',
  },

  svg: {
    marginRight: 20,
  },
});

const IconArrowLeftStyled = styled(IconArrowLeft)({
  display: 'block',

  [media('sm')]: {
    display: 'none',
  },
});

const IconArrowBackStyled = styled(IconArrowBack)({
  display: 'none',

  [media('sm')]: {
    display: 'block',
  },
});

const ButtonBack = props => {
  const { onClick, className } = props;
  return (
    <ButtonBackStyled onClick={onClick} className={className}>
      <IconArrowLeftStyled />
      <IconArrowBackStyled /> <BackLabel>Zurück</BackLabel>
    </ButtonBackStyled>
  );
};

ButtonBack.propTypes = {
  onClick: PropTypes.func,
};

ButtonBack.defaultProps = {
  onClick: () =>
    console.warn(
      'Please define some onClick handler for your ButtonBack component',
    ),
};

export default ButtonBack;
