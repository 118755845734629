import React from 'react';

const IconArrowBack = props => (
  <svg
    viewBox="0 0 45 25"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width={props.width ? props.width : '45px'}
    height={props.height ? props.height : '25px'}
    className={props.className}
  >
    <path
      d="M.707 13.707l13-13m-13 12l13 12m-12.5-11.5h44"
      fill="none"
      stroke="#fff"
      strokeWidth={2}
    />
  </svg>
);

export default IconArrowBack;
