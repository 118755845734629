import React from 'react';
import PropTypes from 'prop-types';
import { css } from 'utils/emotion';

const textDefault = css({
  fontSize: 16,
  lineHeight: 1.7,

  ul: {
    listStyle: 'disc outside none',
    marginLeft: 0,
    paddingLeft: 20,

    li: {
      paddingLeft: 5,
    },
  },
});

export default function Text({ className, children, tag }) {
  const TextTag = `${tag}`;
  const classes = className ? className : '';

  return <TextTag className={`${textDefault} ${classes}`}>{children}</TextTag>;
}

Text.propTypes = {
  tag: PropTypes.string,
};

Text.defaultProps = {
  tag: 'p',
};
