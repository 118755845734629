import React from 'react';
import { RedactorLinkStyled } from './RedactorLinkStyled';

export default function RedactorLink({
  children,
  display,
  target,
  className,
  activeClassName,
  to,
}) {
  return (
    <RedactorLinkStyled
      to={to}
      className={className}
      target={target}
      activeClassName={activeClassName}
      display={display}
    >
      {children}
    </RedactorLinkStyled>
  );
}
