import { styled } from 'utils/emotion';
import Link from 'components/Link';

export const RedactorLinkStyled = styled(Link)(props => ({
  color: props.theme.linkColor,

  '&:hover, &:active, &:focus': {
    color: props.theme.linkColorHover,
  },
}));
