import React from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider } from 'emotion-theming';

import themeDefault from 'components/Layout/themeDefault';
// import themeAlternative from 'components/Layout/themeAlternative';

export default function SwitchTheme({ theme, children }) {
  const themeType = {
    default: themeDefault,
    // alternative: themeAlternative,
  };
  return <ThemeProvider theme={themeType[theme]}>{children}</ThemeProvider>;
}

SwitchTheme.propTypes = {
  theme: PropTypes.string,
};

SwitchTheme.defaultProps = {
  theme: 'default',
};
