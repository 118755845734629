import React from 'react';
import PropTypes from 'prop-types';
import { css, cx } from 'utils/emotion';
import media from 'utils/mediaqueries';
import Brush from 'components/Svg/Brush';
import theme from 'components/Layout/themeDefault';

const brushStyle = css({
  position: 'absolute',
  display: 'block',
  top: 'auto',
  marginTop: '10px',
  marginLeft: '-25px',
  width: 'calc(100% + 50px)',
  maxWidth: '100vw',
  height: '20px',
});

const headlineStyle = css({
  margin: '0',
  padding: '0',
  fontWeight: 600,

  '&.h1': {
    fontSize: 24,
    lineHeight: 1.25,
    [media('xxs')]: {
      fontSize: 28,
      lineHeight: 1.3,
    },
    [media(640)]: {
      fontSize: 46,
    },
  },
  '&.h2, &.h3': {
    fontSize: 20,
    lineHeight: 1.25,
    [media('xxs')]: {
      fontSize: 24,
    },
    [media(640)]: {
      fontSize: 34,
    },
  },
  '&.h4': {
    color: theme.primaryColor,
    fontSize: 18,
    lineHeight: 1.25,
    margin: '10px 0 20px',

    [media(640)]: {
      fontSize: 22,
    },
  },
  '&.h5': {
    fontSize: 16,
    lineHeight: 1.25,
    margin: '5px 0 15px',

    [media(640)]: {
      fontSize: 20,
    },
  },
  '&.h6': {
    fontSize: 16,
    lineHeight: 1.25,
    margin: '5px 0 10px',

    [media(640)]: {
      fontSize: 18,
    },
  },
});

const headlineWithBrush = css({
  position: 'relative',
  paddingBottom: '60px',
  display: 'inline-block',

  [media(540)]: {
    paddingBottom: '80px',
  },
});

const Headline = props => {
  const { brush, className, children, tag } = props;
  const HeadlineTag = tag;

  return (
    <HeadlineTag
      className={cx(
        headlineStyle,
        HeadlineTag,
        brush ? headlineWithBrush : '',
        className,
      )}
    >
      {children}
      {brush && <Brush className={brushStyle} {...brush} />}
    </HeadlineTag>
  );
};

Headline.propTypes = {
  tag: PropTypes.string,
  id: PropTypes.string,
};

Headline.defaultProps = {
  tag: 'h4',
  id: '',
};

export default Headline;
