import React from 'react';
import Helmet from 'react-helmet';
import PropTypes from 'prop-types';
import { StaticQuery, graphql } from 'gatsby';

const Seo = ({
  title,
  description,
  defaults: { defaultTitleTemplate, defaultTitle, defaultDescription },
  fallbacks: { fallbackTitleTemplate, fallbackTitle },
}) => {
  const seo = {
    title,
    description: description || defaultDescription,
  };

  return (
    <>
      <Helmet
        title={seo.title}
        defaultTitle={defaultTitle || fallbackTitle}
        titleTemplate={defaultTitleTemplate || fallbackTitleTemplate}
      >
        {/* Meta tags */}
        {seo.description && (
          <meta name="description" content={seo.description} />
        )}

        {/* Open Graph */}
        {seo.title && <meta property="og:title" content={seo.title} />}
        {seo.description && (
          <meta property="og:description" content={seo.description} />
        )}
      </Helmet>
    </>
  );
};

Seo.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
};

Seo.defaultProps = {
  title: null,
  description: null,
};

export default props => (
  <StaticQuery
    query={graphql`
      query SeoQuery {
        craft {
          globals {
            seo {
              defaultTitleTemplate: seoTitleTemplate
              defaultTitle: seoTitle
              defaultDesc: seoDescription
            }
          }
        }

        site {
          siteMetadata {
            fallbackTitleTemplate: titleTemplate
            fallbackTitle: title
          }
        }
      }
    `}
    render={data => (
      <Seo
        defaults={data.craft.globals.seo}
        fallbacks={data.site.siteMetadata}
        {...props}
      />
    )}
  />
);
