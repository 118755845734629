import React from 'react';

import { styled } from 'utils/emotion';

import Text from 'components/Text';

const RedactorTextStyled = styled(Text)({ margin: '24px 0' }, props => ({}));

export default function RedactorText({ children, element }) {
  return <RedactorTextStyled element={element}>{children}</RedactorTextStyled>;
}
