import React from 'react';

const IconArrowLeft = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 13 21"
    width={props.width ? props.width : '13px'}
    height={props.height ? props.height : '21px'}
    className={props.className}
  >
    <path
      fill="#fff"
      d="M10.938,0,0,10.5l7.52,7.219L10.938,21,13,19.02,4.125,10.5,13,1.98Z"
    />
  </svg>
);

export default IconArrowLeft;
