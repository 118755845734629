import React from 'react';
import { styled } from 'utils/emotion';
import Headline from 'components/Headline';

const RedactorHeadlineStyled = styled(Headline)({});

export default function RedactorHeadline({ element, children, id, className }) {
  return (
    <RedactorHeadlineStyled id={id} tag={element} className={className}>
      {children}
    </RedactorHeadlineStyled>
  );
}
